import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export function useAxiosGet<T>(
  path: string,
  params = {},
  transform?: (data: any) => T,
) {
  transform = transform || ((data: any) => data);
  return useQuery<T>({
    queryKey: ["useAxiosGet", path, params],
    queryFn: async () => transform!((await axios.get(path, { params })).data),
  });
}
