import TripList from "../components/trip-list";
import { StatsGrid } from "../components/stats-grid/stats-grid";

const Trips = () => {
  return (
    <div
      style={{
        flex: 1,
        maxWidth: "1200px",
        width: "100%",
        padding: "0 20px",
        margin: "0 auto",
      }}
    >
      <StatsGrid />
      <TripList />
    </div>
  );
};

export default Trips;
