import { IconUser, IconLuggage } from "@tabler/icons-react";
import exampleVehicle from "../../images/exampleVehicle.png";
import { VehicleType } from "../../models/vehicle";
import classes from "./VehicleTypeCard.module.css";

export default function VehicleTypeCard(props: { vehicle_type: VehicleType }) {
  let { vehicle_type } = props;
  return (
    <div className={classes.container}>
      <img
        src={vehicle_type.image || exampleVehicle}
        alt=""
        width={175}
        height={110}
      />
      <div className={classes.description}>
        <h3 className={classes.vehicleName}>
          {vehicle_type.vehicle_type_name}
        </h3>
        <h4 className={classes.vehicleComparation}>
          Comparable to {vehicle_type.comparable_to}
        </h4>
        <div className={classes.vehicleDetailsContainer}>
          <div className={classes.vehicleDetails}>
            <IconUser size={20} />
            <p>Seats {vehicle_type.max_passengers} people</p>
          </div>
          <div className={classes.vehicleDetails}>
            <IconLuggage size={20} />
            <p>Fits 3 medium suitcases</p>
          </div>
        </div>
      </div>
    </div>
  );
}
