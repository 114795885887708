import { FaqSimple } from "../components/faq/faq";

const FAQ = () => {
  return (
    <div>
      <FaqSimple />
    </div>
  );
};

export default FAQ;
