import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/login";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/carousel/styles.css";
import axios from "axios";
import { DocsPage } from "./pages/docs";
import Settings from "./pages/settings";
import { ContactUsPage } from "./pages/contact";
import FAQ from "./pages/faq";
import PrivateRoutes from "./components/private-routes";
import Layout from "./components/layout";
import { configureAxiosClient } from "./api/axios-client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Homepage } from "./pages/homepage";
import { PrivacyPage } from "./pages/privacy";
import { EditDraftTrip, NewTrip } from "./pages/trip-details";
import TripInformation from "./pages/trip-information";
import Agents from "./pages/agents";
import Trips from "./pages/trips";
import PublicTripInformation from "./pages/public-trip-information";
import Drivers from "./pages/drivers";
import NotFound from "./pages/not-found";
import Unauthorized from "./pages/unauthorized";
import RoundTrip from "./components/round-trip/round-trip";

configureAxiosClient(axios);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider>
        <Notifications />
        <Router>
          <Routes>
            <Route element={<Layout />} path="/">
              <Route element={<Homepage />} path="" />
              <Route element={<ContactUsPage />} path="contact" />
              <Route element={<PrivacyPage />} path="privacy" />
              <Route element={<FAQ />} path="faq" />
              <Route
                element={<PublicTripInformation />}
                path="trip-details/:reference"
              />
            </Route>

            <Route element={<LoginPage />} path="/login" />

            <Route element={<PrivateRoutes />}>
              <Route element={<Layout />} path="/app">
                <Route element={<Unauthorized />} path="unauthorized" />
                <Route element={<Trips />} path="trips" />
                <Route element={<Agents />} path="agents" />
                <Route element={<Drivers />} path="drivers" />
                <Route element={<DocsPage />} path="docs" />
                <Route element={<NewTrip />} path="new-trip" />
                <Route element={<EditDraftTrip />} path="new-trip/:tripId" />
                <Route
                  element={<TripInformation />}
                  path="trip-information/:tripId"
                />
                <Route element={<RoundTrip />} path="round-trip" />
                <Route element={<Settings />} path="settings" />
                <Route element={<Navigate to="/app/trips" replace />} path="" />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
