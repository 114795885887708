import logo from "../../images/logo-big.png";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div style={{ background: "#1d1d1d" }}>
      <div className={classes.container}>
        <img src={logo} alt="Car2Go" width={200} height={70} />
        <div className={classes.footerOptions}>
          <div className={classes.footerLinks}>
            <Link to="/contact">
              <span className={classes.option}>Contact</span>
            </Link>
            <Link to="/privacy">
              <span className={classes.option}>Privacy</span>
            </Link>
            <Link to="/faq">
              <span className={classes.option}>FAQ</span>
            </Link>
          </div>
          <span className={classes.rights}>© 2024 All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
