import { Container, Text, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./Hero.module.css";

export function HeroImageRight() {
  return (
    <div className={classes.root}>
      <Container size="lg">
        <div className={classes.inner}>
          <div className={classes.content}>
            <img
              src={require("../../images/logo-big.png")}
              alt="Next Level of Travelling"
              height="120"
            />

            <Text className={classes.description} mt={30} maw={600}>
              We don't offer just some tiresome transfers (but we can if you
              wants us to). We offer you a travel experience based on your needs
              and preferences. Transfers and excursions specially designed for
              you, whether you like sightseeing, adrenaline or history; a trip
              tailored to your wishes. Just say it and we will do the rest.
            </Text>

            <Button
              variant="gradient"
              gradient={{ from: "blue", to: "cyan" }}
              size="xl"
              className={classes.control}
              component={Link}
              to="/contact"
              mt={40}
            >
              Contact us
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
