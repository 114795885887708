import { useParams } from "react-router-dom";
import { useAxiosGet } from "../utils/api";
import { CompleteTrip } from "../models/trip";
import TripInformationComponent from "../components/trip-information/trip-information";

export default function PublicTripInformation() {
  let { reference } = useParams();
  let q = useAxiosGet<{ result: CompleteTrip }>(
    `/api/public/trip/${reference}`,
  );
  return q.isSuccess ? (
    <TripInformationComponent trip={q.data!.result} />
  ) : null;
}
