import { Box, Table, LoadingOverlay } from "@mantine/core";
import { useAxiosGet } from "../utils/api";
import { Agent } from "../models/agent";
import { Progress } from "@mantine/core";
import { formatDateAndTime } from "../utils/date";
import { formatCentsToEuros } from "../utils/money";

export default function Agents() {
  const agents: any = useAxiosGet("/api/agents/");

  const rows = ((agents.data?.result as Agent[]) || []).map((agent: Agent) => {
    return (
      <Table.Tr key={agent.agent_id}>
        <Table.Td>{agent.agent_name}</Table.Td>
        <Table.Td>{agent.email}</Table.Td>
        <Table.Td>
          {agent.last_login ? formatDateAndTime(agent.last_login) : "-"}
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <TripStates states={agent.stats} />
        </Table.Td>
        <Table.Td>{formatCentsToEuros(agent.stats.revenue)}</Table.Td>
        <Table.Td>
          {agent.stats.last_created_trip_at &&
            formatDateAndTime(agent.stats.last_created_trip_at)}
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <div
      style={{
        maxWidth: "1160px",
        width: "100%",
        margin: "0 auto",
        paddingTop: "20px",
      }}
    >
      <Box pos="relative">
        <LoadingOverlay
          visible={agents.isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Table.ScrollContainer minWidth={800}>
          <Table verticalSpacing="xs">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Agent Name</Table.Th>
                <Table.Th>E-mail</Table.Th>
                <Table.Th>Last Login</Table.Th>
                <Table.Th style={{ width: "300px" }}>Trips</Table.Th>
                <Table.Th>Fees</Table.Th>
                <Table.Th>Last Created Trip</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Box>
    </div>
  );
}

interface States {
  pending_trips: number;
  confirmed_trips: number;
  completed_trips: number;
  canceled_trips: number;
}

function TripStates(props: { states: States }) {
  let { pending_trips, confirmed_trips, completed_trips, canceled_trips } =
    props.states;

  let total =
    pending_trips + confirmed_trips + completed_trips + canceled_trips;
  if (total === 0 || isNaN(total)) return null;

  return (
    <Progress.Root size="xl">
      {pending_trips ? (
        <Progress.Section value={(100 * pending_trips) / total} color="yellow">
          <Progress.Label>{pending_trips}</Progress.Label>
        </Progress.Section>
      ) : null}

      {confirmed_trips ? (
        <Progress.Section value={(100 * confirmed_trips) / total} color="cyan">
          <Progress.Label>{confirmed_trips}</Progress.Label>
        </Progress.Section>
      ) : null}

      {completed_trips ? (
        <Progress.Section value={(100 * completed_trips) / total} color="green">
          <Progress.Label>{completed_trips}</Progress.Label>
        </Progress.Section>
      ) : null}

      {canceled_trips ? (
        <Progress.Section value={(100 * canceled_trips) / total} color="red">
          <Progress.Label>{canceled_trips}</Progress.Label>
        </Progress.Section>
      ) : null}
    </Progress.Root>
  );
}
