import { FeaturesGrid } from "../components/features-grid/features-grid";
import { HeroImageRight } from "../components/homepage-hero/hero";

export function Homepage() {
  return (
    <>
      <HeroImageRight />
      <FeaturesGrid />
    </>
  );
}
