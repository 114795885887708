import {
  ThemeIcon,
  Text,
  Title,
  Container,
  SimpleGrid,
  rem,
} from "@mantine/core";
import {
  IconShieldHalfFilled,
  IconBrandCitymapper,
  IconCar,
} from "@tabler/icons-react";
import classes from "./FeaturesGrid.module.css";

export const MOCKDATA = [
  {
    icon: IconShieldHalfFilled,
    title: "Professional local drivers",
    description: `
    Car 2 Go drivers are all professionals with years of experience.
    They will guide you and provide lots of useful information.
    Nobody knows its country better than a local!
    `,
  },

  {
    icon: IconBrandCitymapper,
    title: "Door-to-door service",
    description: `No need for you to hire additional transportation service, we bring you door-to-door and our friendly drivers will also help you with your luggage.`,
  },

  {
    icon: IconCar,
    title: "Clean, modern, comfortable vehicles",
    description: `Your safety is very important to us, therefore we have high vehicle standards, ensuring frequent inspections and meticulous upkeep.`,
  },
];

interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" c="dimmed" lh={1.6}>
        {description}
      </Text>
    </div>
  );
}

export function FeaturesGrid() {
  const features = MOCKDATA.map((feature, index) => (
    <Feature {...feature} key={index} />
  ));

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>
        Custom made transfers and excursions
        <br />
        specially designed for you!
      </Title>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          We offer you a travel experience based on your needs and preferences.
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={{ base: 1, sm: 2, md: 3 }}
        spacing={{ base: "xl", md: 50 }}
        verticalSpacing={{ base: "xl", md: 50 }}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
