import { useState } from "react";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Container,
  Button,
  Alert,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { loginUser } from "../api/users";
import { LoginFormData } from "../models/user";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";

const LoginPage = () => {
  const loginForm = useForm<LoginFormData>({
    initialValues: {
      username: "",
      password: "",
    },
    validate: {
      username: (value) =>
        value.length < 1 ? "Username must not be empty" : null,
      password: (value) =>
        value.length < 1 ? "Password must not be empty" : null,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (data: LoginFormData) => {
    try {
      setLoading(true);
      await loginUser(data);
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate("/app/trips");
      }
    } catch (error) {
      setErrorMessage(
        "Invalid username or password. Please check your credentials and try again.",
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container size={420} my={40}>
      <Title ta="center">
        <img src={logo} alt="Car2Go logo" height={30} />
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={loginForm.onSubmit(handleSubmit)}>
          <TextInput
            label="Username"
            placeholder="Username"
            required
            error={loginForm.errors.username}
            radius="lg"
            {...loginForm.getInputProps("username")}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            radius="lg"
            {...loginForm.getInputProps("password")}
          />
          <Checkbox label="Remember me" mt="lg" />
          {errorMessage && (
            <Alert
              variant="light"
              color="red"
              radius="lg"
              mt="lg"
              p="10px"
              icon={<IconInfoCircle />}
            >
              {errorMessage}
            </Alert>
          )}
          <Button loading={loading} radius="lg" fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default LoginPage;
