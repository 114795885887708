import React from "react";
import { useImmer } from "use-immer";
import { ActionIcon, Overlay, Paper, Text, Title } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import {
  IconCircleMinus,
  IconCirclePlus,
  IconInfoCircle,
  IconMinus,
  IconX,
} from "@tabler/icons-react";
import { POI } from "../../models/trip";
import defaultPoiBackground from "../../images/defaultPoiBackground.png";
import classes from "./CarouselSlide.module.css";
import { useState } from "react";

export interface SelectedPOI {
  poi: POI;
  time: number;
  price: number;
}

interface Handlers {
  addTime: (id: number, time: number) => void;
  remove: (id: number) => void;
  add: (poi: POI) => void;
}

export function useSelectedPoiState(): [SelectedPOI[], Handlers] {
  let [state, setState] = useImmer<SelectedPOI[]>([]);

  let addTime = React.useCallback(
    (id: number, time: number) =>
      setState((draft) => {
        let poi = draft.find(({ poi }) => poi.poi_id === id);
        if (poi) {
          poi.time += time;
          poi.time = Math.max(60, poi.time);
          poi.price = 100 * (30 + (poi.time - 60) / 3); // cents
        }
      }),
    [setState],
  );

  let remove = React.useCallback(
    (id: number) =>
      setState((draft) => {
        let i = draft.findIndex(({ poi }) => poi.poi_id === id);
        if (i !== null) {
          draft.splice(i, 1);
        }
      }),
    [setState],
  );

  let add = React.useCallback(
    (poi: POI) =>
      setState((draft) => {
        draft.push({ poi, time: 60, price: 30 * 100 });
      }),
    [setState],
  );

  return [state, { addTime, remove, add }];
}

interface Props extends SelectedPOI {
  handlers: Handlers;
  setHoveredPoi: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function SelectedCarouselSlide({
  poi,
  time,
  price,
  handlers,
  setHoveredPoi,
}: Props) {
  const [showInformation, setShowInformation] = useState<boolean>(false);

  return (
    <Carousel.Slide
      key={poi.poi_id}
      className={classes.selectedCarouselSlide}
      onMouseEnter={() => {
        setHoveredPoi(poi.poi_id);
      }}
      onMouseLeave={() => {
        setHoveredPoi(null);
      }}
    >
      <Paper
        shadow="md"
        p="md"
        radius="md"
        style={{
          backgroundImage: poi.photo
            ? `url(${poi.photo})`
            : `url(${defaultPoiBackground})`,
        }}
        className={`${classes.card} ${classes.selected}`}
      >
        <>
          <div>
            <Text
              className={classes.category}
              size="xs"
              style={{ color: poi.photo ? "white" : "black" }}
            >
              {poi.category_name}
            </Text>
            <Title
              order={3}
              className={classes.title}
              style={{ color: poi.photo ? "white" : "black" }}
            >
              {poi.name}{" "}
              {poi.description.length > 0 && (
                <IconInfoCircle
                  className={classes.icon}
                  onClick={() => setShowInformation(true)}
                  width={25}
                  height={25}
                />
              )}
            </Title>
          </div>

          <div>
            <ActionIcon
              variant="filled"
              color="red"
              size="xl"
              radius="xl"
              aria-label="Add"
              onClick={() => handlers.remove(poi.poi_id)}
            >
              <IconMinus style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </div>
          {showInformation && poi.description.length > 0 && (
            <Overlay className={classes.overlay} backgroundOpacity={0.85}>
              <IconX
                className={classes.icon}
                onClick={() => setShowInformation(false)}
                width={25}
                height={25}
                color="white"
              />
              <p className={classes.overlayDescription}>{poi.description}</p>
            </Overlay>
          )}
        </>
      </Paper>
      <div className={classes.poiInformation}>
        <div className={classes.timeContainer}>
          <IconCircleMinus
            className={`${classes.icon} ${time === poi.min_duration_minutes ? classes.disabled : ""}`}
            onClick={() => handlers.addTime(poi.poi_id, -30)}
          />
          <p className={classes.forbiddenSelect}>{time} min</p>
          <IconCirclePlus
            className={`${classes.icon} ${time === poi.max_duration_minutes ? classes.disabled : ""}`}
            onClick={() => handlers.addTime(poi.poi_id, 30)}
          />
        </div>
        <p className={classes.forbiddenSelect}>
          Price: {(price / 100).toFixed(2)}€
        </p>
      </div>
    </Carousel.Slide>
  );
}
