import axios from "axios";
import { LoginFormData, User } from "../models/user";

export const loginUser = async (loginData: LoginFormData) => {
  const res = await axios.post("/api/auth", {
    username: loginData.username,
    password: loginData.password,
  });
  const user = res.data;
  return user;
};

export const isUserLoggedIn = async () => {
  try {
    const res = await axios.get("/api/auth");
    return res.data as User;
  } catch (e) {
    return null;
  }
};

export const logoutUser = async () => {
  await axios.delete("/api/auth");
};
