import { Box } from "@mantine/core";
import { ContactUs } from "./contact-us";

export function ContactUsPage() {
  return (
    <Box
      pt={20}
      pl={20}
      pr={20}
      style={{ maxWidth: "1200px", margin: "0 auto" }}
    >
      <ContactUs />
    </Box>
  );
}
