import { useEffect, useRef, useMemo } from "react";
import Map, { Layer, Marker, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import type { MapRef } from "react-map-gl";
import { POI } from "../../models/trip";

interface Props {
  overview_polyline: Array<[number, number]>;
  pois: POI[];
  hoveredPoi: number | null;
  interests: string[];
}

export default function MapContainer({
  overview_polyline,
  pois,
  hoveredPoi,
  interests,
}: Props) {
  const path = useMemo(() => overview_polyline || [], [overview_polyline]);
  const isPathValid = path.length > 1;
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if (mapRef.current && isPathValid) {
      const bbox = [path[0], path[path.length - 1]];
      mapRef.current.fitBounds(bbox as any, {
        padding: { top: 40, bottom: 40, left: 40, right: 40 },
      });
    }
  }, [path, isPathValid]);

  let data = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: path,
    },
  };

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken="pk.eyJ1IjoiaGJhbmRvdiIsImEiOiJjbHVoMTZqazEya2p5MnNsMHF1c3ZicmV6In0.wcM_OYGTxxDZq8D8tnfKxA"
      initialViewState={{
        latitude: 44.8146563,
        longitude: 15.86896,
        zoom: 6,
      }}
      style={{ width: "100%", height: "100%" }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      attributionControl={false}
    >
      {isPathValid && (
        <Marker longitude={path[0][0]} latitude={path[0][1]} color="green" />
      )}
      {isPathValid && (
        <Marker
          longitude={path[path.length - 1][0]}
          latitude={path[path.length - 1][1]}
          color="red"
        />
      )}
      {pois.length > 0 &&
        pois
          .filter(
            (poi) =>
              interests.length === 0 ||
              interests.includes(poi.category_id.toString()),
          )
          .map((poi) => (
            <Marker
              key={poi.poi_id}
              longitude={poi.location.lng}
              latitude={poi.location.lat}
              color="#f7be6d"
            />
          ))}

      {pois.length > 0 &&
        pois
          .filter(({ poi_id }) => poi_id !== hoveredPoi)
          .filter(
            (poi) =>
              interests.length === 0 ||
              interests.includes(poi.category_id.toString()),
          )
          .map((poi) => (
            <Marker
              key={poi.poi_id}
              longitude={poi.location.lng}
              latitude={poi.location.lat}
              color={poi.poi_color}
            />
          ))}
      <Source id="polylineLayer" type="geojson" data={data as any}>
        <Layer
          id="lineLayer"
          type="line"
          source="my-data"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "rgba(59, 91, 219, 1)",
            "line-width": 6,
          }}
        />
      </Source>
    </Map>
  );
}
