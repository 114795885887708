import { Badge, Divider } from "@mantine/core";
import { CompleteTrip, STATUS_COLOR, STATUS_TEXT } from "../../models/trip";
import classes from "./TripInformation.module.css";
import { formatCentsToEuros } from "../../utils/money";
import dayjs from "dayjs";
import DriverCard from "../../containers/driver-card/driver-card";
import VehicleTypeCard from "../../containers/vehicle-type-card/vehicle-type-card";
import Map from "../trip-details/map";
import Stripe from "../stripe/stripe";

export interface Props {
  trip: CompleteTrip;
}

const TripInformationComponent = (props: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h1 className={classes.title}>
          Booking reference <b>{props.trip.reference}</b>
        </h1>
        <div className={classes.tripStatus}>
          <p className={classes.status}>Status:</p>
          <Badge color={STATUS_COLOR[props.trip.status]} size="xl">
            {STATUS_TEXT[props.trip.status]}
          </Badge>
        </div>
      </div>

      <div className={classes.contentGrid}>
        <div className={`${classes.driver} ${classes.content}`}>
          <h2 className={classes.subtitle}>Driver</h2>
          {props.trip.driver && (
            <DriverCard
              vehicle={props.trip.vehicle}
              driver={props.trip.driver}
            />
          )}
        </div>

        {props.trip.vehicle_type && (
          <div className={`${classes.vehicle} ${classes.content}`}>
            <div className={classes.vehicleHeader}>
              <h2 className={classes.subtitle}>Vehicle</h2>
            </div>
            <VehicleTypeCard vehicle_type={props.trip.vehicle_type} />
            <div className={classes.price}>
              <span>Price:</span> {formatCentsToEuros(props.trip.price_total)}{" "}
            </div>
          </div>
        )}

        <div className={`${classes.tripInformation} ${classes.content}`}>
          <h2 className={classes.subtitle}>Summary</h2>
          <div className={classes.informationGrid}>
            <div className={classes.information}>
              <span className={classes.description}>From:</span>
              <span>{props.trip.start_locality}</span>
            </div>

            <div className={classes.information}>
              <span className={classes.description}>To:</span>
              <span>{props.trip.end_locality}</span>
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Date:</span>
              <span>{dayjs(props.trip.pickup_date).format("DD.MM.YYYY.")}</span>
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Time:</span>
              <span>{props.trip.pickup_time}</span>
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Passengers:</span>
              <span>{props.trip.passengers}</span>
            </div>

            <Divider className={classes.information} my="sm" />

            <div className={classes.information}>
              <span className={classes.description}>Customer name:</span>
              <span>
                {props.trip.customer_first_name} {props.trip.customer_last_name}
              </span>
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Customer email:</span>
              <span>{props.trip.customer_email}</span>
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Customer phone:</span>
              <span>{props.trip.customer_phone}</span>
            </div>

            <Divider className={classes.information} my="sm" />

            <div className={classes.information}>
              <span className={classes.description}>Airport pickup:</span>
              {props.trip.info.airport_pickup ? (
                <span>
                  Yes for flight number: {props.trip.info.flight_number}
                </span>
              ) : (
                <span>No</span>
              )}
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Child seats:</span>
              {props.trip.info.child_seats ? <span>Yes</span> : <span>No</span>}
            </div>

            <div className={classes.information}>
              <span className={classes.description}>Notes:</span>
              {props.trip.info.notes}
            </div>
          </div>
        </div>
      </div>
      {props.trip.status === "pending_payment" && (
        <Stripe
          trip_id={props.trip.trip_id}
          price_total={props.trip.price_total}
        />
      )}
      <div className={classes.map}>
        <Map
          overview_polyline={props.trip.extra.overview_polyline || []}
          pois={props.trip.extra.pois || []}
          hoveredPoi={null}
          interests={[]}
        />
      </div>
    </div>
  );
};

export default TripInformationComponent;
