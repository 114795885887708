import { useCallback, useMemo, useState } from "react";
import {
  Input,
  Button,
  NumberInput,
  Textarea,
  Switch,
  Modal,
  MultiSelect,
} from "@mantine/core";
import classes from "./TripDetails.module.css";
import { IconCalendar } from "@tabler/icons-react";
import { DateInput, TimeInput } from "@mantine/dates";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import Autocomplete from "react-google-autocomplete";
import {
  GoogleCity,
  POICategory,
  Trip,
  TripExtra,
  TripForm,
  emptyGoogleCity,
  emptyTripForm,
} from "../../models/trip";
import Map from "./map";
import axios, { AxiosError } from "axios";
import { isoDate } from "../../utils/date";
import { formatCentsToEuros } from "../../utils/money";
import { Carousel } from "@mantine/carousel";
import CarouselSlide from "../../containers/carousel-slide/carousel-slide";
import { useDisclosure } from "@mantine/hooks";
import VehicleTypeCard from "../../containers/vehicle-type-card/vehicle-type-card";
import { VehicleType } from "../../models/vehicle";
import SelectedCarouselSlide, {
  useSelectedPoiState,
} from "../../containers/carousel-slide/selected-carousel-slide";
import { useAxiosGet } from "../../utils/api";
import { notifications } from "@mantine/notifications";

export interface Props {
  trip?: Trip;
}

const TripDetailsComponent = (props: Props) => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const isDraft = props.trip != null;
  const tripId = props.trip?.trip_id;

  const navigate = useNavigate();
  const [tripExtra, setTripExtra] = useState<TripExtra | null>(
    props.trip?.extra || null,
  );

  const [interests, setInterests] = useState<string[]>([]);
  const [hoveredPoi, setHoveredPoi] = useState<number | null>(null);
  const [selectedPois, selectedPoisHandlers] = useSelectedPoiState();
  const [isReadyForReview, setIsReadyForReview] = useState<boolean>(
    false || props.trip?.status === "draft",
  );

  const poiCategories: any = useAxiosGet("/api/poi_categories");
  const transformedData = (poiCategories.data?.result || []).map(
    (pc: POICategory) => ({
      value: pc.category_id.toString(),
      label: pc.category_name,
    }),
  );

  const [vehicleType, setVehicleType] = useState<VehicleType | null>(
    props.trip?.extra?.vehicle_types.find(
      (vt) => vt.vehicle_type_id === props.trip?.vehicle_type_pk,
    ) || null,
  );
  const form = useForm<TripForm>({
    initialValues: { ...emptyTripForm, ...props.trip },
    validate: {
      customer_first_name: (value) =>
        value.length < 2 ? "First name is required" : null,
      customer_last_name: (value) =>
        value.length < 2 ? "Last name is required" : null,
      customer_email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      customer_phone: (value) =>
        value.length < 2 ? "Customer phone is required" : null,
    },
  });

  const [opened, { open, close }] = useDisclosure(false);

  const poiPrice = useMemo(
    () => selectedPois.reduce((acc, sp) => acc + sp.price, 0),
    [selectedPois],
  );

  const handleSubmit = useCallback(
    async (trip: TripForm, is_draft: boolean) => {
      try {
        let data = (
          await axios.post("/api/trip/", {
            ...trip,
            is_draft,
            pickup_date: isoDate(trip.pickup_date),
            start_locality: trip.start_loc.formatted_address,
            end_locality: trip.end_loc.formatted_address,
            pickup_time: trip.pickup_time || null,
            extra: tripExtra,
            vehicle_type_id: vehicleType?.vehicle_type_id,
            price_total: (vehicleType?.price_cents || 0) + poiPrice,
          })
        ).data as { trip_id: number };

        if (is_draft) {
          navigate(`/app/new-trip/${data.trip_id}`, { replace: true });
        } else {
          navigate(`/app/trip-information/${data.trip_id}`, { replace: true });
        }
      } catch (e) {
        notifications.show({
          title: "Error",
          message: "Please try again later",
          color: "red",
        });

        if (
          e instanceof AxiosError &&
          e.response &&
          Array.isArray(e?.response?.data?.detail)
        ) {
          let errors = (e.response.data.detail as any[]).reduce((errs, e) => {
            errs[e.loc[2] || "global"] = e.msg;
            return errs;
          }, {});
          console.log("Error details", e.response.data.detail);
          form.setErrors(errors);
        } else {
          form.setFieldError(
            "global",
            `An unexpected error has happened: ${e?.toString()}`,
          );
        }
      }
    },
    [form, navigate, tripExtra, vehicleType, poiPrice],
  );

  const handleUpdate = useCallback(async () => {
    try {
      let response = await axios.post("/api/update_trip/", {
        start_loc: form.values.start_loc,
        end_loc: form.values.end_loc,
      });
      let data = response.data as TripExtra;
      if (data.vehicle_types?.length > 0) {
        let valid = data.vehicle_types.filter(
          (vt) => vt.max_passengers >= form.values.passengers,
        );
        if (valid.length > 0) {
          setVehicleType(valid[0]);
        } else {
          setVehicleType(data.vehicle_types[0]);
        }
      }
      setTripExtra(data);
      setIsReadyForReview(true);
    } catch (e) {
      console.log(e);
    }
  }, [form]);

  const deleteTrip = useCallback(async () => {
    await axios.delete(`/api/trip/${tripId}`);
    navigate("/app", { replace: true });
  }, [tripId, navigate]);

  return (
    <>
      <div className={classes.wrap}>
        <div className={classes.container}>
          <form
            className={classes.formContainer}
            onSubmit={form.onSubmit((values, e) =>
              handleSubmit(
                values,
                (e as any)?.nativeEvent?.submitter?.name === "save_draft",
              ),
            )}
          >
            <div className={classes.content}>
              <div className={classes.dataColumn}>
                <div className={classes.informationDetails}>
                  <h2 className={classes.subtitle}>Basic information</h2>

                  <div className={classes.formStructure}>
                    <div className={classes.inputForm}>
                      <Input.Wrapper
                        label="From - To"
                        className={classes.inputContainer}
                      >
                        <Autocomplete
                          name="start_loc"
                          className={classes.googleInput}
                          apiKey={googleApiKey}
                          options={{
                            types: ["(cities)"],
                            componentRestrictions: {
                              country: [
                                "hr",
                                "si",
                                "ba",
                                "it",
                                "at",
                                "rs",
                                "hu",
                              ],
                            },
                          }}
                          onPlaceSelected={(place: any) => {
                            const selectedCity: GoogleCity = {
                              ...emptyGoogleCity,
                            };
                            selectedCity.formatted_address =
                              place.formatted_address;
                            selectedCity.place_id = place.place_id;
                            selectedCity.lat = place.geometry.location.lat();
                            selectedCity.lng = place.geometry.location.lng();
                            form.setFieldValue("start_loc", selectedCity);
                          }}
                          placeholder="From"
                          defaultValue={
                            form.values.start_loc?.formatted_address || ""
                          }
                        />
                      </Input.Wrapper>
                      <Autocomplete
                        name="end_loc"
                        className={classes.googleInput}
                        apiKey={googleApiKey}
                        options={{
                          types: ["(cities)"],
                          componentRestrictions: {
                            country: ["hr", "si", "ba", "it", "at", "rs", "hu"],
                          },
                        }}
                        onPlaceSelected={(place: any) => {
                          const selectedCity: GoogleCity = {
                            ...emptyGoogleCity,
                          };
                          selectedCity.formatted_address =
                            place.formatted_address;
                          selectedCity.place_id = place.place_id;
                          selectedCity.lat = place.geometry.location.lat();
                          selectedCity.lng = place.geometry.location.lng();
                          form.setFieldValue("end_loc", selectedCity);
                        }}
                        placeholder="To"
                        defaultValue={
                          form.values.end_loc?.formatted_address || ""
                        }
                      />
                    </div>

                    <div className={classes.datePassengers}>
                      <div className={classes.datetime}>
                        <DateInput
                          name="pickup_date"
                          label="Date & Time"
                          rightSection={<IconCalendar size={16} color="#000" />}
                          {...form.getInputProps("pickup_date")}
                        />
                        <TimeInput
                          name="pickup_time"
                          className={classes.timePicker}
                          {...form.getInputProps("pickup_time")}
                        />
                      </div>

                      <div className={classes.passengers}>
                        <NumberInput
                          name="passengers"
                          label="Passengers"
                          min={1}
                          max={7}
                          {...form.getInputProps("passengers")}
                        />
                        <Button onClick={() => handleUpdate()}>Update</Button>
                      </div>
                    </div>
                  </div>

                  <p className={classes.distance}>
                    {tripExtra &&
                      `Distance ${Math.ceil(tripExtra.distance / 1000.0)}km`}
                  </p>
                </div>

                <div className={classes.vehicleButtons}>
                  <div className={classes.vehicle}>
                    {vehicleType && (
                      <>
                        <div className={classes.vehicleHeader}>
                          <h2 className={classes.subtitle}>Vehicle</h2>
                        </div>
                        <VehicleTypeCard vehicle_type={vehicleType} />
                        <div className={classes.price}>
                          <span>Price: </span>
                          <b>
                            {formatCentsToEuros(
                              vehicleType.price_cents + poiPrice,
                            )}
                          </b>
                        </div>
                      </>
                    )}
                    {!vehicleType && (
                      <>
                        <div className={classes.vehicleHeader}>
                          <h2 className={classes.subtitle}>Vehicle</h2>
                        </div>
                        <VehicleTypeCard
                          vehicle_type={{
                            vehicle_type_id: 0,
                            vehicle_type_name: "Sedan",
                            max_passengers: 3,
                            price: 0,
                            price_cents: 0,
                            comparable_to: "",
                            image: "",
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div className={classes.buttons}>
                    {isDraft && (
                      <Button
                        name="delete_draft"
                        variant="outline"
                        color="red"
                        size="md"
                        onClick={open}
                      >
                        Delete Draft
                      </Button>
                    )}
                    <Button
                      name="save_draft"
                      variant="outline"
                      color="teal"
                      size="md"
                      disabled={tripExtra == null || vehicleType == null}
                      onClick={() => handleSubmit(form.values, true)}
                    >
                      Save Draft
                    </Button>
                    <Button
                      disabled={!isReadyForReview}
                      type="submit"
                      name="save"
                      color="teal"
                      size="md"
                    >
                      Ready for Review
                    </Button>
                  </div>
                </div>
              </div>

              <div className={classes.dataColumn}>
                <div className={classes.informationDetails}>
                  <h2 className={classes.subtitle}>Customer Contact</h2>
                  <div className={classes.formStructure}>
                    <div className={classes.inputForm}>
                      <div className={classes.contract}>
                        <Input.Wrapper
                          label="Name"
                          className={classes.inputContainer}
                        >
                          <Input
                            name="customer_first_name"
                            placeholder="First Name"
                            className={classes.inputContainer}
                            {...form.getInputProps("customer_first_name")}
                          />
                        </Input.Wrapper>
                        <Input
                          name="customer_last_name"
                          placeholder="Last Name"
                          className={classes.inputContainer}
                          {...form.getInputProps("customer_last_name")}
                        />
                      </div>

                      <div className={classes.contract}>
                        <Input.Wrapper
                          label="E-mail"
                          className={classes.inputContainer}
                        >
                          <Input
                            name="customer_email"
                            placeholder="E-mail address"
                            className={classes.inputContainer}
                            {...form.getInputProps("customer_email")}
                          />
                        </Input.Wrapper>
                        <Input.Wrapper
                          label="Phone"
                          className={classes.inputContainer}
                        >
                          <Input
                            name="customer_phone"
                            placeholder="e.g. 91 235 5678"
                            className={classes.inputContainer}
                            {...form.getInputProps("customer_phone")}
                          />
                        </Input.Wrapper>
                      </div>
                      <MultiSelect
                        className={classes.customerInterest}
                        checkIconPosition="left"
                        data={transformedData}
                        label="Customer Interests"
                        placeholder="Pick interests"
                        value={interests}
                        onChange={setInterests}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.informationDetails}>
                  <h2 className={classes.subtitle}>Additional Information</h2>
                  <div className={classes.formStructure}>
                    <div className={classes.inputForm}>
                      <Textarea
                        name="info.notes"
                        label="Notes"
                        placeholder="Edit text"
                        className={classes.textarea}
                        {...form.getInputProps("info.notes")}
                      />
                      <div className={classes.extraInfo}>
                        <Switch
                          name="info.airport_pickup"
                          label="Airport pickup"
                          className={classes.extraInfoInput}
                          style={{ padding: "3px 0" }}
                          checked={form.values.info?.airport_pickup || false}
                          {...form.getInputProps("info.airport_pickup")}
                        />
                        {form.values.info?.airport_pickup && (
                          <Input
                            name="info.flight_number"
                            placeholder="Flight number"
                            className={classes.inputContainer}
                            {...form.getInputProps("info.flight_number")}
                          />
                        )}
                      </div>
                      <div className={classes.extraInfo}>
                        <Switch
                          name="info.child_seats"
                          label="Child seats"
                          className={classes.extraInfoInput}
                          checked={form.values.info?.child_seats || false}
                          {...form.getInputProps("info.child_seats")}
                        />
                        {form.values.info?.child_seats && (
                          <Input
                            name="info.child_info"
                            placeholder="Age of children"
                            className={classes.inputContainer}
                            {...form.getInputProps("info.child_info")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {tripExtra &&
            tripExtra.pois.length > 0 &&
            selectedPois.length > 0 && (
              <div className={classes.selectedPois}>
                <Carousel
                  slideSize={{ base: "50%", sm: "50%" }}
                  slideGap={10}
                  align="start"
                  styles={{
                    controls: {
                      top: "100px",
                    },
                  }}
                >
                  {selectedPois.map((selectedPoi) => (
                    <SelectedCarouselSlide
                      key={selectedPoi.poi.poi_id}
                      {...selectedPoi}
                      handlers={selectedPoisHandlers}
                      setHoveredPoi={setHoveredPoi}
                    />
                  ))}
                </Carousel>
              </div>
            )}
        </div>

        <div className={classes.dataDisplay}>
          <div className={classes.pois}>
            {tripExtra && tripExtra.pois.length > 0 && (
              <Carousel
                slideSize={{ base: "50%", sm: "25%" }}
                slidesToScroll="auto"
                slideGap={10}
                height={200}
                align="start"
                className={`${tripExtra} && ${tripExtra.pois.length > 0} && pois`}
              >
                {tripExtra.pois
                  .filter(
                    (poi) =>
                      (interests.length === 0 ||
                        interests.includes(poi.category_id.toString())) &&
                      selectedPois.find((sp) => sp.poi.poi_id === poi.poi_id) ==
                        null,
                  )
                  .map((poi) => (
                    <CarouselSlide
                      key={poi.poi_id}
                      poi={poi}
                      onAdd={() => selectedPoisHandlers.add(poi)}
                      setHoveredPoi={setHoveredPoi}
                    />
                  ))}
              </Carousel>
            )}
          </div>
          <div className={classes.map}>
            <Map
              overview_polyline={tripExtra?.overview_polyline || []}
              pois={tripExtra?.pois || []}
              hoveredPoi={hoveredPoi}
              interests={interests}
            />
          </div>
        </div>
      </div>
      <Modal.Root opened={opened} onClose={close}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title className={classes.modalTitle}>
              Delete trip
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete trip?</p>
            <div className={classes.modalButtons}>
              <Button color="red" onClick={deleteTrip}>
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default TripDetailsComponent;
