import { Button, Center, Menu } from "@mantine/core";
import { IconChevronDown, IconMenu2, IconX } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import classes from "./Header.module.css";
import { useCallback, useEffect, useState } from "react";
import { isUserLoggedIn, logoutUser } from "../../api/users";
import { User } from "../../models/user";
import { useDisclosure } from "@mantine/hooks";

const Header = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [user, setUser] = useState<User | null>(null);

  const handleLogout = async () => {
    await logoutUser();
    navigate("/");
    window.location.reload();
  };

  const checkUser = useCallback(async () => {
    const res = await isUserLoggedIn();
    setUser(res);
  }, []);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <>
      <div style={{ background: "#1d1d1d" }}>
        <div className={classes.container}>
          <img
            className={classes.logo}
            src={logo}
            alt="Car2Go logo"
            height={25}
            onClick={() => navigate("/")}
          />
          {!user && (
            <Button variant="outline" component={Link} to="/login">
              Sign in
            </Button>
          )}
          {user && (
            <>
              <div className={classes.headerOptions}>
                <Link to="/app/trips">
                  <span className={classes.option}>Trips</span>
                </Link>
                {(user.is_staff || user.is_agency_admin) && (
                  <Link to="/app/agents">
                    <span className={classes.option}>Agents</span>
                  </Link>
                )}
                {user.is_staff && (
                  <>
                    <Link to="/app/drivers">
                      <span className={classes.option}>Drivers</span>
                    </Link>
                    <Link to="/admin/">
                      <span className={classes.option}>Admin</span>
                    </Link>
                  </>
                )}
                <Link to="/app/docs">
                  <span className={classes.option}>Docs</span>
                </Link>
                {(user.is_staff || user.is_agent) && (
                  <Button
                    color="green"
                    component={Link}
                    to="/app/new-trip"
                  >
                    New Trip
                  </Button>
                )}
                {(user.is_staff || user.is_agent) && false && (
                  <Button
                    color="green"
                    component={Link}
                    to="/app/round-trip"
                  >
                    Round Trip
                  </Button>
                )}
                <Menu
                  key="header-menu"
                  trigger="hover"
                  transitionProps={{ exitDuration: 0 }}
                  withinPortal
                >
                  <Menu.Target>
                    <Center>
                      <span className={classes.headerLink}>
                        {user.first_name} {user.last_name}
                      </span>
                      <IconChevronDown
                        className={classes.icon}
                        size="0.9rem"
                        stroke={1.5}
                      />
                    </Center>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item key="logout" onClick={() => handleLogout()}>
                      Sign out
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
              <IconMenu2 className={classes.hamburgerIcon} onClick={open} />
            </>
          )}
        </div>
      </div>

      {user && (
        <div
          className={`${classes.mobileContainer} ${
            opened ? classes.modalOpen : ""
          }`}
        >
          <div className={classes.mobileHeader}>
            <img
              className={classes.logo}
              src={logo}
              alt="CTG logo"
              height={25}
              onClick={() => navigate("/home")}
            />
            <IconX className={classes.xIcon} onClick={close} />
          </div>
          <div className={classes.mobileMenu}>
            <Link to="/app/trips" onClick={close}>
              <span className={classes.option}>Trips</span>
            </Link>
            {(user.is_staff || user.is_agency_admin) && (
              <Link to="/app/agents" onClick={close}>
                <span className={classes.option}>Agents</span>
              </Link>
            )}
            {user.is_staff && (
              <>
                <Link to="/app/drivers" onClick={close}>
                  <span className={classes.option}>Drivers</span>
                </Link>
                <Link to="/aadmin" onClick={close}>
                  <span className={classes.option}>Admin</span>
                </Link>
              </>
            )}
            <Link to="/app/docs" onClick={close}>
              <span className={classes.option}>Docs</span>
            </Link>
            {(user.is_staff || user.is_agent) && (
              <Link to="/app/new-trip" onClick={close}>
                <span className={classes.option}>New trip</span>
              </Link>
            )}
            {(user.is_staff || user.is_agent) && (
              <Link to="/app/round-trip" onClick={close}>
                <span className={classes.option}>Round trip</span>
              </Link>
            )}
            <span className={classes.option} onClick={close}>
              Logout
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
