import { Box, Table, LoadingOverlay } from "@mantine/core";
import { useAxiosGet } from "../utils/api";
import { Driver } from "../models/driver";
import { Progress } from "@mantine/core";
import { formatDateAndTime } from "../utils/date";
import { formatCentsToEuros } from "../utils/money";

export default function Drivers() {
  const drivers: any = useAxiosGet("/api/drivers/");

  const rows = ((drivers.data?.result as Driver[]) || []).map(
    (driver: Driver) => {
      return (
        <Table.Tr key={driver.driver_id}>
          <Table.Td>{driver.driver_name}</Table.Td>
          <Table.Td>{driver.email}</Table.Td>
          <Table.Td>
            {driver.last_login ? formatDateAndTime(driver.last_login) : "-"}
          </Table.Td>
          <Table.Td style={{ textAlign: "center" }}>
            <TripStates states={driver.stats} />
          </Table.Td>
          <Table.Td>{formatCentsToEuros(driver.stats.revenue)}</Table.Td>
        </Table.Tr>
      );
    },
  );

  return (
    <div
      style={{
        maxWidth: "1160px",
        width: "100%",
        margin: "0 auto",
        paddingTop: "20px",
      }}
    >
      <Box pos="relative">
        <LoadingOverlay
          visible={drivers.isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Table.ScrollContainer minWidth={800}>
          <Table verticalSpacing="xs">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Driver Name</Table.Th>
                <Table.Th>E-mail</Table.Th>
                <Table.Th>Last Login</Table.Th>
                <Table.Th style={{ width: "300px" }}>Trips</Table.Th>
                <Table.Th>Fees</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Box>
    </div>
  );
}

interface States {
  pending_trips: number;
  confirmed_trips: number;
  completed_trips: number;
  canceled_trips: number;
}

function TripStates(props: { states: States }) {
  let { pending_trips, confirmed_trips, completed_trips, canceled_trips } =
    props.states;

  let total =
    pending_trips + confirmed_trips + completed_trips + canceled_trips;
  if (total === 0 || isNaN(total)) return null;

  return (
    <Progress.Root size="xl">
      {pending_trips ? (
        <Progress.Section value={(100 * pending_trips) / total} color="yellow">
          <Progress.Label>{pending_trips}</Progress.Label>
        </Progress.Section>
      ) : null}

      {confirmed_trips ? (
        <Progress.Section value={(100 * confirmed_trips) / total} color="cyan">
          <Progress.Label>{confirmed_trips}</Progress.Label>
        </Progress.Section>
      ) : null}

      {completed_trips ? (
        <Progress.Section value={(100 * completed_trips) / total} color="green">
          <Progress.Label>{completed_trips}</Progress.Label>
        </Progress.Section>
      ) : null}

      {canceled_trips ? (
        <Progress.Section value={(100 * canceled_trips) / total} color="red">
          <Progress.Label>{canceled_trips}</Progress.Label>
        </Progress.Section>
      ) : null}
    </Progress.Root>
  );
}
