import { AxiosStatic } from "axios";

function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const configureAxiosClient = (axios: AxiosStatic) => {
  axios.interceptors.request.use((config) => {
    config.headers!["X-CSRFToken"] = getCookie("csrftoken");
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error.response);
      switch (error.response.status) {
        case 403:
          window.location.href = "/app/unauthorized";
          break;
        default:
          console.error(error);
          return Promise.reject(error);
      }
    },
  );
};
