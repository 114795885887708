import { Carousel } from "@mantine/carousel";
import { POI } from "../../models/trip";
import { Paper, Title, Text, ActionIcon, Overlay } from "@mantine/core";
import { IconPlus, IconInfoCircle, IconX } from "@tabler/icons-react";
import defaultPoiBackground from "../../images/defaultPoiBackground.png";
import classes from "./CarouselSlide.module.css";
import { useState } from "react";

type Props = {
  poi: POI;
  onAdd: (poi: POI) => void;
  setHoveredPoi: React.Dispatch<React.SetStateAction<number | null>>;
};

export default function CarouselSlide({ poi, onAdd, setHoveredPoi }: Props) {
  const [showInformation, setShowInformation] = useState<boolean>(false);

  return (
    <Carousel.Slide
      key={poi.poi_id}
      onMouseEnter={() => {
        setHoveredPoi(poi.poi_id);
      }}
      onMouseLeave={() => {
        setHoveredPoi(null);
      }}
    >
      <Paper
        shadow="md"
        p="md"
        radius="md"
        style={{
          backgroundImage: poi.photo
            ? `url(${poi.photo})`
            : `url(${defaultPoiBackground})`,
          backgroundSize: "cover",
          height: "200px",
        }}
        className={classes.card}
      >
        <>
          <div>
            <Text
              className={classes.category}
              size="xs"
              style={{ color: poi.photo ? "white" : "black" }}
            >
              {poi.category_name}
            </Text>
            <Title
              order={3}
              className={classes.title}
              style={{ color: poi.photo ? "white" : "black" }}
            >
              {poi.name}{" "}
              {poi.description.length > 0 && (
                <IconInfoCircle
                  className={classes.icon}
                  onClick={() => setShowInformation(true)}
                  width={25}
                  height={25}
                />
              )}
            </Title>
          </div>

          <ActionIcon
            variant="filled"
            color="green"
            size="xl"
            radius="xl"
            aria-label="Add"
            onClick={() => onAdd(poi)}
          >
            <IconPlus style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
          {showInformation && poi.description.length > 0 && (
            <Overlay className={classes.overlay} backgroundOpacity={0.85}>
              <IconX
                className={classes.icon}
                onClick={() => setShowInformation(false)}
                width={25}
                height={25}
                color="white"
              />
              <p className={classes.overlayDescription}>{poi.description}</p>
            </Overlay>
          )}
        </>
      </Paper>
    </Carousel.Slide>
  );
}
