import { Group, Paper, SimpleGrid, Text } from "@mantine/core";
import {
  IconUserPlus,
  IconReceipt2,
  IconCoin,
  //IconArrowUpRight,
  //IconArrowDownRight,
  IconMapPin,
} from "@tabler/icons-react";
import classes from "./StatsGrid.module.css";
import { formatCentsToEuros } from "../../utils/money";
import { useAxiosGet } from "../../utils/api";

const icons = {
  user: IconUserPlus,
  coin: IconCoin,
  receipt: IconReceipt2,
  miles: IconMapPin,
};

const data = [
  {
    title: "Created Trips",
    desc: "Non-draft trips",
    icon: "miles",
    value: (d: any) => d?.created_trips,
    diff: 34,
    key: "created-trip",
  },
  {
    title: "Completed Trips",
    desc: "Completed or in-progress trips",
    icon: "miles",
    value: (d: any) => d?.completed_trips,
    diff: 34,
    key: "completed-trip",
  },
  {
    title: "Profit",
    desc: "Total agent fee",
    icon: "coin",
    value: (d: any) => (d ? formatCentsToEuros(d.completed_revenue) : null),
    diff: +15,
    key: "profit",
  },
  {
    title: "New customers",
    desc: "Unique Customers",
    icon: "user",
    value: (d: any) => d?.customers,
    diff: 3,
    key: "new-customers",
  },
] as const;

export function StatsGrid() {
  const apiData = useAxiosGet("/api/stats/");

  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    //const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Paper withBorder p="md" radius="md" key={stat.key}>
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text fz="xl" className={classes.value}>
            {stat.value(apiData?.data) || "-"}
          </Text>
          <Text
            c={stat.diff > 0 ? "teal" : "red"}
            fz="sm"
            fw={500}
            className={classes.diff}
          >
            {/* TODO add diff with previous period
            <span>{stat.diff}%</span>
            <DiffIcon size="1rem" stroke={1.5} />
            */}
          </Text>
        </Group>

        <Text fz="xs" c="dimmed" mt={7}>
          {stat.desc}
        </Text>
      </Paper>
    );
  });
  return (
    <div className={classes.root}>
      <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }}>{stats}</SimpleGrid>
    </div>
  );
}
