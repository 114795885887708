import { Container, Title } from "@mantine/core";
import classes from "./Privacy.module.css";

export function PrivacyPage() {
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title ta="center" className={classes.title}>
        Privacy Policy
      </Title>

      <p>
        Car 2 Go takes your privacy seriously. To better protect your privacy we
        provide this privacy policy notice explaining the way your personal
        information is collected and used.
      </p>

      <h2 id="collection-of-routine-information">
        Collection of Routine Information
      </h2>
      <p>
        This website track basic information about their users. This information
        includes, but is not limited to, IP addresses, browser details,
        timestamps and referring pages. None of this information can personally
        identify specific user to this website. The information is tracked for
        routine administration and maintenance purposes.
      </p>

      <h2 id="cookies">Cookies</h2>
      <p>
        Where necessary, this website uses cookies to store information about a
        visitor's preferences and history in order to better serve the user
        and/or present the user with customized content.
      </p>

      <h2 id="links-to-third-party-websites">Links to Third Party Websites</h2>
      <p>
        We have included links on this website for your use and reference. we
        are not responsible for the privacy policies on these websites. You
        should be aware that the privacy policies of these websites may differ
        from our own.
      </p>
      <h2 id="security">Security</h2>
      <p>
        The security of your personal information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage, is 100% secure. While we strive to use commercially
        acceptable means to protect your personal information, we cannot
        guarantee its absolute security.
      </p>
      <h2 id="changes-to-this-privacy-policy">
        Changes To This Privacy Policy
      </h2>
      <p>
        This Privacy Policy is effective as of 2024-03-01 and will remain in
        effect except with respect to any changes in its provisions in the
        future, which will be in effect immediately after being posted on this
        page.
      </p>
      <p>
        we reserve the right to update or change our Privacy Policy at any time
        and you should check this Privacy Policy periodically. If we make any
        material changes to this Privacy Policy, we will notify you either
        through the email address you have provided us, or by placing a
        prominent notice on our website.
      </p>
      <h2 id="contact-information">Contact Information</h2>
      <p>
        For any questions or concerns regarding the privacy policy, please send
        us an email to{" "}
        <a href="mailto:contact@car2go.com.hr">info@car2go.com.hr</a>.
      </p>
    </Container>
  );
}
