import { useState } from "react";
import {
  GoogleCity,
  RoundTripForm,
  emptyGoogleCity,
  emptyRoundTripForm,
} from "../../models/trip";
import classes from "./RoundTrip.module.css";
import { useForm } from "@mantine/form";
import RoundTripMap from "./round-trip-map";
import { Button, Group, Input } from "@mantine/core";
import Autocomplete from "react-google-autocomplete";
import { IconTrash, IconArrowUp, IconArrowDown } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";

export default function RoundTrip() {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const form = useForm<RoundTripForm>({
    initialValues: {
      ...emptyRoundTripForm,
      stops: emptyRoundTripForm.stops.map(() => ({
        ...emptyGoogleCity,
        id: uuidv4(),
      })),
    },
  });
  const [readyForSubmit, setReadyForSubmit] = useState<boolean>(false);

  const handleAddStop = () => {
    form.insertListItem("stops", { ...emptyGoogleCity, id: uuidv4() });
  };

  const handleRemoveStop = (index: number) => {
    form.removeListItem("stops", index);
  };

  const handleStopChange = (index: number, stop: GoogleCity) => {
    form.setFieldValue(`stops.${index}`, stop);
  };

  const getLabel = (index: number) => {
    if (index === 0) return "Start";
    if (index === form.values.stops.length - 1) return "End";
    return `Stop ${index}`;
  };

  const moveStopUp = (index: number) => {
    if (index > 0) {
      form.reorderListItem("stops", { from: index, to: index - 1 });
    }
  };

  const moveStopDown = (index: number) => {
    if (index < form.values.stops.length - 1) {
      form.reorderListItem("stops", { from: index, to: index + 1 });
    }
  };

  return (
    <div className={classes.wrap}>
      <form
        className={classes.formContainer}
        onSubmit={form.onSubmit((values) => console.log(values))}
      >
        <div className={classes.stopsContainer}>
          {form.values.stops.map((stop, index) => (
            <Input.Wrapper
              key={stop.id}
              label={getLabel(index)}
              className={classes.inputContainer}
            >
              <div className={classes.stopRow}>
                <Autocomplete
                  name={`stop_${index}`}
                  className={classes.googleInput}
                  apiKey={googleApiKey}
                  options={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: ["hr", "si", "ba", "it", "at", "rs", "hu"],
                    },
                  }}
                  onPlaceSelected={(place: any) => {
                    const selectedCity: GoogleCity = {
                      ...emptyGoogleCity,
                      formatted_address: place.formatted_address,
                      place_id: place.place_id,
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                      id: stop.id,
                    };
                    handleStopChange(index, selectedCity);
                  }}
                />
                <div className={classes.iconsContainer}>
                  {index !== 0 && index !== form.values.stops.length - 1 && (
                    <IconTrash
                      style={{ color: "#fa5252", cursor: "pointer" }}
                      onClick={() => handleRemoveStop(index)}
                    />
                  )}
                  <IconArrowUp
                    style={{ cursor: "pointer" }}
                    onClick={() => moveStopUp(index)}
                  />
                  <IconArrowDown
                    style={{ cursor: "pointer" }}
                    onClick={() => moveStopDown(index)}
                  />
                </div>
              </div>
            </Input.Wrapper>
          ))}
          <Button onClick={handleAddStop}>Add new stop</Button>
        </div>
        <div className={classes.buttonsContainer}>
          <Button>Update</Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
      <div className={classes.map}>
        <RoundTripMap overview_polyline={form.values.overview_polyline} />
      </div>
    </div>
  );
}
