import { Container, Title, Accordion } from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./Faq.module.css";

export function FaqSimple() {
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title ta="center" className={classes.title}>
        Frequently Asked Questions
      </Title>

      <Title order={2} className={classes.subtitle}>
        General
      </Title>
      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="car-2-go">
          <Accordion.Control>What is Car 2 Go?</Accordion.Control>
          <Accordion.Panel>
            Car 2 Go is a platform that connects you with local drivers who will
            transport you door-to- door, from one city to the next. You can also
            book and explore recommended highlights along the way. Our drivers
            are very familiar with the area you will be travelling and are able
            to give a lot of usefull information.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="open-hours">
          <Accordion.Control>When are you open?</Accordion.Control>
          <Accordion.Panel>
            Our drivers are available 24/7, so you can book a trip at the time
            that suits your needs. And if you ever need assistance, our Customer
            Service team is available around the clock.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="how-to-book">
          <Accordion.Control>How do I book?</Accordion.Control>
          <Accordion.Panel>
            Simply choose your origin and destination via our homepage and enter
            your departure date and time. Browse and add sightseeing stops along
            the way by following the booking instructions provided on screen.
            It&#39;s a simple, easy, and fun way to book your next adventure!
            And if you don’t see your desired route on our website, you can{" "}
            <Link to="/app/new-trip">request a custom trip</Link>. You can even
            book without knowing your exact pick-up or drop-off address - we let
            you add these later!
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="emails-from-ctg">
          <Accordion.Control>
            Why haven’t I received any emails from Car 2 Go?
          </Accordion.Control>
          <Accordion.Panel>
            We send a confirmation email as soon as any of our drivers accepts
            the requested trip (approximately in 1 hour) where you will recieve
            all information required for the trip. If you haven’t received any
            emails from us, it’s possible there’s a typo with your email address
            or they are going to your spam folder. If you haven’t received any
            emails, this does NOT mean your trip is canceled! To update your
            contact information, please <Link to="/contact">contact us</Link>.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="safe-travel">
          <Accordion.Control>
            Is it safe to travel with Car 2 Go?
          </Accordion.Control>
          <Accordion.Panel>
            We always screen our drivers for criminal and traffic records, and
            personally interview each one/ inspect their cars for safety,
            comfort, and reliability. All cars must be less than ten years old,
            in excellent operating condition, and insured as required by local
            law. And because our drivers are locals, they&#39;re familiar with
            the rules and customs of the road. We believe this provides a safer,
            more relaxing experience for you.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="personal-data">
          <Accordion.Control>
            How do I delete my personal data?
          </Accordion.Control>
          <Accordion.Panel>
            If you booked with us through our website, please send a request to
            support@car2go.com.hr and we will gladly remove all your personal
            information.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Title order={2} className={classes.subtitle}>
        Special requests
      </Title>
      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="round-trip">
          <Accordion.Control>Can I book a round trip?</Accordion.Control>
          <Accordion.Panel>
            You can book a direct transfer, transfer with sightseeing along the
            way or an excursion (round trip). For excursions simply input same
            pickup and drop off location and add the locations you would like to
            visit.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="smoke-free">
          <Accordion.Control>Can I request a smoke-free car?</Accordion.Control>
          <Accordion.Panel>
            We strive to make your trip as comfortable as possible, and the air
            you breathe in the car is an important part of this. All drivers are
            required to keep their cars smoke-free, and generally as
            scent-neutral as possible. Which means no smoke, no dust, and no air
            fresheners.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="my-pet">
          <Accordion.Control>Can I bring my pet with me?</Accordion.Control>
          <Accordion.Panel>
            Yes, we’re happy to accommodate your animal companion! Please let us
            know in advance if you will be traveling with pets, including
            service and emotional support animals, so we can ensure there will
            be enough space for you, your pet, and your luggage in the vehicle.
            If you don’t inform us in advance, we can’t guarantee that your
            driver will be able to accommodate you or offer any refund.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="child-seat">
          <Accordion.Control>
            Will the driver provide child car seat?
          </Accordion.Control>
          <Accordion.Panel>
            Of course, you only need to request for it during the booking
            process, and inform us about the child age so we can provide
            suitable child seat for you.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="flight-delay">
          <Accordion.Control>
            What happens if my flight is being delayed?
          </Accordion.Control>
          <Accordion.Panel>
            Your driver will wait up to 60 minutes for airport pickups in case
            of delay, but please make sure to contact our support team as soon
            as you learn of the delay. We’ll do our best to accommodate your new
            arrival time.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="more-time">
          <Accordion.Control>
            What if I need more time at the stop on the day?
          </Accordion.Control>
          <Accordion.Panel>
            We understand that sometimes it’s hard to tear yourself away from
            your sightseeing stop. If you find you’d like extra time, please
            make arrangements with your driver. There will be an hourly fee
            based on the country in which you are traveling. While they’ll do
            their best to accommodate, sometimes their schedule won’t allow for
            them to stay longer.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Title order={2} className={classes.subtitle}>
        Trip details
      </Title>
      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="vehicle-type">
          <Accordion.Control>
            What type of vehicle will I get?
          </Accordion.Control>
          <Accordion.Panel>
            You will receive the vehicle that best fits the number of travelers
            in your group, ranging from a sedan to a bus for groups up to 50.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="luggage">
          <Accordion.Control>Will my luggage fit?</Accordion.Control>
          <Accordion.Panel>
            Each passenger is allowed one large bag (29&quot; x 21&quot; x
            11&quot; / 74 x 53 x 28 cm) and one small bag (22&quot; x 14&quot; x
            9&quot; / 56 x 36 x 23 cm). We always send the vehicle that is the
            best fit for you and your luggage, so make sure to select the actual
            amount of luggage you are traveling with when booking. If you have
            oversized luggage, or are unsure if your luggage will fit,{" "}
            <Link to="/contact">contact us</Link>.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="sightseeing-stop">
          <Accordion.Control>
            Will my sightseeing stop be open?
          </Accordion.Control>
          <Accordion.Panel>
            We can’t guarantee that every attraction will be open for every
            trip, especially during the off- season. We recommend checking the
            opening hours in advance. If you have any questions about your trip,
            feel free to{" "}
            <Link to="/contact">contact our customer service department</Link>.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="driver-pickup">
          <Accordion.Control>
            Where will my driver pick me up?
          </Accordion.Control>
          <Accordion.Panel>
            Your driver can pick you up at your hotel, apartment, cruise ship
            dock, or any other pick-up point accessible by car. This applies to
            your drop-off location as well. No need to worry about buses, cabs,
            or other connections – Car 2 Go provides door-to-door service! If
            you happen to be in a pedestrian-only area, your driver will meet
            you at a designated pick-up location.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="confirm-pickup">
          <Accordion.Control>
            Will my driver call me to confirm pick-up?
          </Accordion.Control>
          <Accordion.Panel>
            Your driver will meet you at the agreed time and place and won’t be
            contacting you before, unless necessary. You’ll receive a reminder
            with your driver’s name and mobile number a few days before
            departure in case you need to contact them.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="tour-guide">
          <Accordion.Control>
            Will my driver speak English and be a tour guide?
          </Accordion.Control>
          <Accordion.Panel>
            Car 2 Go partner drivers speak English as well as their native
            language. They are very familiar with the area you will be
            travelling and will provide a lot of usefull information, there is
            no need for you to spend extra money for a tour guide unless you
            specifically ask for it.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Title order={2} className={classes.subtitle}>
        Payment
      </Title>
      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="how-to-pay">
          <Accordion.Control>How can I pay?</Accordion.Control>
          <Accordion.Panel>
            You can pay in advance via credit card or in cash directly to your
            driver.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="additional-fees">
          <Accordion.Control>
            What&#39;s included in the price? Are there any additional fees?
          </Accordion.Control>
          <Accordion.Panel>
            The quoted price is final and there are no additional fees for our
            services. However, optional third party activities such as tours or
            attraction fees are not included in the price. Rest assured there
            will be no surprises or additional fees from Car 2 Go.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="gratuity">
          <Accordion.Control>Is gratuity included/expected?</Accordion.Control>
          <Accordion.Panel>
            Gratuity is not included in the price of your booking. If you are
            satisfied with your driver’s service, you can reward them with a tip
            according to local tipping culture (10% will be sufficient).
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="payment-failed">
          <Accordion.Control>
            My payment failed, how can I fix it?
          </Accordion.Control>
          <Accordion.Panel>
            If your payment did not go through, please try again with a
            different card (we accept Visa, Mastercard, and AmEx). If you are
            still having trouble, please <Link to="/contact">contact us</Link>.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Title order={2} className={classes.subtitle}>
        Changes &amp; cancellations
      </Title>
      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="changes">
          <Accordion.Control>
            How can I make changes to my booking?
          </Accordion.Control>
          <Accordion.Panel>
            You can make changes to your itinerary, including adding or changing
            sightseeing stops, free of charge up to 36 hours before departure
            (some changes may affect the price of the trip). If you need to make
            a last-minute change less than 36 hours before departure, we’ll do
            our best to accommodate it (additional fees may apply). To make
            changes, simply log in to your booking on the website, or{" "}
            <Link to="/contact">contact us</Link>.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="cancellation-policy">
          <Accordion.Control>
            What is your cancellation policy?
          </Accordion.Control>
          <Accordion.Panel>
            For Car 2 go’s private service, you can cancel for a full refund up
            to 24 hours before departure.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
