import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { createPaymentIntent } from "../../api/stripe";
import CheckoutForm from "./checkout-form";
import { notifications } from "@mantine/notifications";
import "./stripe.css";

let stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = loadStripe(`${stripeKey}`);

type Props = {
  price_total: number;
  trip_id: number;
};

export default function Stripe({ price_total, trip_id }: Props) {
  const [clientSecret, setClientSecret] = useState<string>("");

  const createPayment = useCallback(async () => {
    try {
      const res = await createPaymentIntent(trip_id);
      setClientSecret(res);
    } catch {
      notifications.show({
        title: "Error",
        message: "Please try again later",
        color: "red",
      });
    }
  }, [trip_id]);

  useEffect(() => {
    createPayment();
  }, [createPayment]);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div className="stripe-container">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm price_total={price_total} />
        </Elements>
      )}
    </div>
  );
}
