import { useCallback, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isUserLoggedIn } from "../api/users";

const PrivateRoutes = () => {
  const [signedIn, setSignedIn] = useState<boolean | null>(null);
  const location = useLocation();

  const checkUser = useCallback(async () => {
    try {
      await isUserLoggedIn();
      setSignedIn(true);
    } catch {
      setSignedIn(false);
    }
  }, []);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  if (signedIn == null) {
    return null;
  } else {
    return signedIn ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace state={{ from: location }} />
    );
  }
};

export default PrivateRoutes;
