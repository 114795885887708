import Footer from "./footer/footer";
import Header from "./header/header";
import { Outlet } from "react-router-dom";
import classes from "./layout.module.css";

const Layout = () => {
  return (
    <div className={classes.outer}>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
