import { IconPhone, IconMail, IconCar } from "@tabler/icons-react";
import defaultUser from "../../images/defaultUser.png";
import classes from "./DriverCard.module.css";
import { Driver } from "../../models/driver";
import { Vehicle } from "../../models/vehicle";

const DriverCard = (props: { driver: Driver; vehicle?: Vehicle }) => {
  let { driver, vehicle } = props;
  return (
    <div className={classes.container}>
      <img src={defaultUser} alt="" width={150} height={140} />

      <div className={classes.description}>
        <h3 className={classes.driverName}>{driver.driver_name}</h3>
        <div className={classes.driverDetailsContainer}>
          <div className={classes.driverDetails}>
            <IconPhone size={20} />
            <p>{driver.phone}</p>
          </div>

          {driver.email && (
            <div className={classes.driverDetails}>
              <IconMail size={20} />
              <p>{driver.email}</p>
            </div>
          )}

          {vehicle && (
            <div className={classes.driverDetails}>
              <IconCar size={20} />
              <p>
                {vehicle.vehicle_make} {vehicle.vehicle_model} (
                {vehicle.license_plate})
              </p>
            </div>
          )}

          {/*
          <div className={classes.driverDetails}>
            <IconCar size={20} />
            <p>89 rides</p>
          </div>
          */}
        </div>
      </div>
    </div>
  );
};

export default DriverCard;
