import { useState } from "react";
import {
  Box,
  Table,
  Anchor,
  Pagination,
  Badge,
  Center,
  LoadingOverlay,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { formatCentsToEuros } from "../utils/money";
import { STATUS_COLOR, STATUS_TEXT } from "../models/trip";
import { useAxiosGet } from "../utils/api";

export default function TripList() {
  const PER_PAGE = 10;
  const [activePage, setPage] = useState(1);
  const trips = useAxiosGet("/api/trip/", {
    limit: PER_PAGE,
    offset: (activePage - 1) * PER_PAGE,
  });
  const totalPages = Math.floor((trips.data?.count || 0) / PER_PAGE) + 1;

  const rows = (trips.data?.items || []).map((row) => {
    return (
      <Table.Tr key={row.trip_id}>
        <Table.Td>
          {row.status === "draft" ? (
            <Anchor
              component={Link}
              to={`/app/new-trip/${row.trip_id}`}
              fz="sm"
            >
              {row.start_locality} - {row.end_locality}
            </Anchor>
          ) : (
            <Anchor
              component={Link}
              to={`/app/trip-information/${row.trip_id}`}
              fz="sm"
            >
              {row.start_locality} - {row.end_locality}
            </Anchor>
          )}
        </Table.Td>
        <Table.Td>{row.pickup_date}</Table.Td>
        <Table.Td>{row.reference}</Table.Td>
        <Table.Td>
          {row.customer_first_name} {row.customer_last_name}
        </Table.Td>
        <Table.Td>{row.agent_name}</Table.Td>
        <Table.Td>{row.driver_name}</Table.Td>
        <Table.Td>{formatCentsToEuros(row.price_total)}</Table.Td>
        <Table.Td>
          <Badge color={STATUS_COLOR[row.status]}>
            {STATUS_TEXT[row.status]}
          </Badge>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <div
      style={{
        maxWidth: "1160px",
        width: "100%",
        margin: "0 auto",
        paddingTop: "20px",
      }}
    >
      <Box pos="relative">
        <LoadingOverlay
          visible={trips.isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Table.ScrollContainer minWidth={800}>
          <Table verticalSpacing="xs">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>From - To</Table.Th>
                <Table.Th>Date</Table.Th>
                <Table.Th>Reference</Table.Th>
                <Table.Th>Customer</Table.Th>
                <Table.Th>Agent</Table.Th>
                <Table.Th>Driver</Table.Th>

                <Table.Th>Price</Table.Th>
                <Table.Th>Status</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Box>
      <Center mt={60} mb={20}>
        <Pagination total={totalPages} value={activePage} onChange={setPage} />
      </Center>
    </div>
  );
}
