import { Title, Text } from "@mantine/core";

export default function Unauthorized() {
  return (
    <div className="not-found-container">
      <Title>Nothing to see here</Title>
      <Text c="dimmed" size="lg" ta="center">
        You do not have permission to view the content of this page.
      </Text>
    </div>
  );
}
