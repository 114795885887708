import { Container, Title, Accordion } from "@mantine/core";
import classes from "./Docs.module.css";

export function DocsPage() {
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title ta="center" className={classes.title}>
        Documentation
      </Title>

      <Title order={2} className={classes.subtitle}>
        Booking States Diagram
      </Title>

      <Container>
        <img
          src={require("./states.png")}
          className={classes.image}
          alt="A diagram showing booking states: draft, pending, confirmed, completed."
        />
      </Container>

      <Title order={2} className={classes.subtitle}>
        Frequently Asked Questions
      </Title>

      <Container size="sm">
        <Accordion variant="separated">
          <Accordion.Item className={classes.item} value="1">
            <Accordion.Control>How is agency fee calculated?</Accordion.Control>
            <Accordion.Panel>
              Aenean aliquet blandit tempor. Praesent ac est ipsum. Nam gravida
              feugiat enim. Cras fringilla magna at erat dapibus facilisis.
              Maecenas ullamcorper, ipsum id condimentum fringilla, enim enim
              molestie massa, eu posuere metus nulla ut tellus. Proin bibendum
              malesuada lorem id gravida. Suspendisse a tristique enim, a
              tristique augue.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="2">
            <Accordion.Control>
              In which countries does Car 2 Go operate in?
            </Accordion.Control>
            <Accordion.Panel>
              Aenean aliquet blandit tempor. Praesent ac est ipsum. Nam gravida
              feugiat enim. Cras fringilla magna at erat dapibus facilisis.
              Maecenas ullamcorper, ipsum id condimentum fringilla, enim enim
              molestie massa, eu posuere metus nulla ut tellus. Proin bibendum
              malesuada lorem id gravida. Suspendisse a tristique enim, a
              tristique augue.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion variant="separated">
          <Accordion.Item className={classes.item} value="3">
            <Accordion.Control>
              How to create user accounts for agents?
            </Accordion.Control>
            <Accordion.Panel>
              Aenean aliquet blandit tempor. Praesent ac est ipsum. Nam gravida
              feugiat enim. Cras fringilla magna at erat dapibus facilisis.
              Maecenas ullamcorper, ipsum id condimentum fringilla, enim enim
              molestie massa, eu posuere metus nulla ut tellus. Proin bibendum
              malesuada lorem id gravida. Suspendisse a tristique enim, a
              tristique augue.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="4">
            <Accordion.Control>How can a trip be canceled?</Accordion.Control>
            <Accordion.Panel>
              Aenean aliquet blandit tempor. Praesent ac est ipsum. Nam gravida
              feugiat enim. Cras fringilla magna at erat dapibus facilisis.
              Maecenas ullamcorper, ipsum id condimentum fringilla, enim enim
              molestie massa, eu posuere metus nulla ut tellus. Proin bibendum
              malesuada lorem id gravida. Suspendisse a tristique enim, a
              tristique augue.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </Container>
  );
}
