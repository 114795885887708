import { Driver } from "./driver";
import { Vehicle, VehicleType } from "./vehicle";

export interface TripForm {
  start_loc: GoogleCity;
  end_loc: GoogleCity;
  start_locality: string;
  end_locality: string;
  pickup_date: Date;
  pickup_time: string;
  passengers: number;

  customer_first_name: string;
  customer_last_name: string;
  customer_email: string;
  customer_phone: string;
  status: string;

  info: {
    notes: string;
    airport_pickup: boolean;
    flight_number: string;
    child_seats: boolean;
    child_info: string;
  };
}

export interface Trip extends TripForm {
  trip_id: number;
  vehicle_type_pk: number;
  price_total: number;
  extra: TripExtra;
}

export interface CompleteTrip extends Trip {
  reference: string;
  driver?: Driver;
  vehicle?: Vehicle;
  vehicle_type?: VehicleType;
}

export function fromTripData(data: any): Trip {
  data.pickup_date = new Date(data.pickup_date + "T12:00");
  return data as Trip;
}

export interface GoogleCity {
  formatted_address: string;
  place_id: string;
  lat: number;
  lng: number;
  id: string;
}

export interface POI {
  poi_id: number;
  name: string;
  category_id: number;
  category_name: string;
  poi_color: string;
  min_duration_minutes: number;
  max_duration_minutes: number;
  photo: string | null;
  location: { lat: number; lng: number };
  distance: number;
  description: string;
  price: number;
  time: number;
}

export interface POICategory {
  category_id: number;
  category_name: string;
  color: string;
}

export interface TripExtra {
  distance: number;
  overview_polyline: Array<[number, number]>;
  vehicle_types: VehicleType[];
  pois: POI[];
}

export const emptyGoogleCity: GoogleCity = {
  formatted_address: "",
  place_id: "",
  lat: 0,
  lng: 0,
  id: "",
};

export const emptyTripForm: TripForm = {
  start_loc: emptyGoogleCity,
  end_loc: emptyGoogleCity,
  start_locality: "",
  end_locality: "",
  pickup_date: new Date(),
  pickup_time: "12:00",
  passengers: 1,
  customer_first_name: "",
  customer_last_name: "",
  customer_email: "",
  customer_phone: "",
  status: "",
  info: {
    notes: "",
    airport_pickup: false,
    flight_number: "",
    child_seats: false,
    child_info: "",
  },
};

interface Status {
  draft: string;
  pending_review: string;
  pending_payment: string;
  pending_driver: string;
  confirmed: string;
  in_progress: string;
  completed: string;
  canceled: string;
  [key: string]: string;
}

export const STATUS_TEXT: Status = {
  draft: "Draft",
  pending_review: "Pending",
  pending_payment: "Pending",
  pending_driver: "Pending",
  confirmed: "Confirmed",
  in_progress: "In progress",
  completed: "Completed",
  canceled: "Canceled",
};

export const STATUS_COLOR: Status = {
  draft: "gray",
  pending_review: "yellow",
  pending_payment: "yellow",
  pending_driver: "yellow",
  confirmed: "cyan",
  in_progress: "lime",
  completed: "green",
  canceled: "red",
};

export interface TripStats {
  pending_trips: number;
  confirmed_trips: number;
  completed_trips: number;
  canceled_trips: number;
  last_created_trip_at: string | null;
  revenue: number;
}

export interface RoundTripForm {
  stops: GoogleCity[];
  overview_polyline: Array<[number, number]>;
}

export const emptyRoundTripForm: RoundTripForm = {
  stops: [emptyGoogleCity, emptyGoogleCity],
  overview_polyline: [],
};
