import { useParams } from "react-router-dom";
import TripDetailsComponent from "../components/trip-details/trip-details";
import { Trip, fromTripData } from "../models/trip";
import { useAxiosGet } from "../utils/api";

export function NewTrip() {
  return <TripDetailsComponent />;
}

export function EditDraftTrip() {
  let { tripId } = useParams();
  let trip = useAxiosGet<Trip>(`/api/trip/${tripId}`, {}, fromTripData);
  return trip.isSuccess ? <TripDetailsComponent trip={trip.data} /> : null;
}
