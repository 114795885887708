import { useParams } from "react-router-dom";
import TripInformationComponent from "../components/trip-information/trip-information";
import { useAxiosGet } from "../utils/api";
import { CompleteTrip } from "../models/trip";

const TripInformation = () => {
  let { tripId } = useParams();
  let q = useAxiosGet<{ result: CompleteTrip }>(
    `/api/complete_trip/${tripId}/`,
  );
  return q.isSuccess ? (
    <TripInformationComponent trip={q.data!.result} />
  ) : null;
};

export default TripInformation;
